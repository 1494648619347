:root {
  --font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
h1,h1,h3,h2,h4,h5 {
  font-family: Palatino, Palatino Linotype, Palatino LT STD, Book Antiqua, Georgia, serif;
}
h1 {
  font-size: 3rem
}
h2 {
  font-size: 2.2rem
}
h3 {
  font-size: 1.9rem
}
h4 {
  font-size: 1.5rem
}


