nav.toc {
  border: 1px solid #555;
  padding: 15px;
  background-color: $title-bg-color;
  float: right;
  margin-left: 10px;
  ul li {
    display: block;
    list-style-type: none;
    margin: 0;
  }
  ul {
    padding: 0;
  }
  li a {
    color: #bbb;
    &:hover {
      color: #eee;
    }
  }

  .h2 {
    font-size: 20px;
    a {
      font-weight: normal;
    }
  }
  .h3 {
    font-size: 16px;
    padding-left: 20px;
    a {
      font-weight: normal;
    }
  }
  .h4 {
    font-size: 14px;
    padding-left: 24px;
    a {
      font-weight: normal;
    }
  }
  header {
    text-align: center;
  }
  @media screen and (max-width: 700px) {
    display: block;
    float: none;
  }
}
