@import "./_mvp.scss";

@import './_typography.scss';

@import './_bookmark.scss';
@import './_layout.scss';
@import './_toc.scss';
@import './_font.scss';
@import './_article.scss';
@import './_syntax.scss';

:root {
  --color-secondary: #0d82e9;
}

.swup-transition-main {
  transition: opacity .2s;
}
.card {
  @extend aside !optional;
  width: auto !important;
}
span.label {
  display: inline-block;
  @extend sup !optional;
  font-size: small;
  position: static;
}
.home-boxes {
  flex-wrap: nowrap;
  .card {
    width: 45% !important;
  }
  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    aside { display: block; width: auto !important; }

  }
}
.bio-photo {
  width: 150px;
  height: 150px;
  border-radius: 150px;
}
.nice-to-meet-you {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: initial;
  img { margin-right: 15px }
  h2 { margin-top: 0 }
  @media screen and (max-width: 800px) {
    display: block;
  }
}
@media screen and (max-width: 800px) {
  header svg {
    height: 1.5rem;
  }
  nav ul li {
    margin: 0 0.25rem;
  }
  section aside {
    margin: 1rem 0;
  }
}
img.aligncenter {
  max-width: 700px;
  width: 100%;
  height: auto;
  padding: 2px;
  display: block;
  margin: auto;
}
.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 100%;
  img {
    max-width: 600px;
    @media screen and (max-width: 700px) {
      max-width: initial;
      width: 100%;
      height: auto;
      padding: 2px;
      margin: 0;
    }
  }
}

h1 {
  @media screen and (max-width: 700px) {
    line-height: 1.3;
    font-size: 32px;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-bg: #191919;
  }
}
.chip {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
