img.alignright {
  clear: right;
  float: right;
}
img.alignleft {
  clear: left;
  float: left;
}
sup a {
  color: white;
}
.img-frame {
  margin: auto;
  display: block;
  border: 2px solid #aaa;
  padding: 10px;
  border-radius: 5px;
}
img.aligncenter {
  max-width: 100%;
  @extend .img-frame;
  margin: 20px;
}
.related {
  ul { list-style-type: none; padding: 0 }
}
.tag {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

pre, code {
  max-width: 100%;
}
.age-warning {
  display: flex;
  margin: 30px 0;
  padding: 30px 15px;

  svg {
    width: 30px;
    margin-right: 15px;
  }
}
