$title-bg-color: #343342;

header svg {
  height: 2rem;
}
article h1 {
  margin-bottom: 0;
}
article .date {
  margin-bottom: 2rem;
}
header {
  padding: 1rem;
}
.title-container {
  @extend main !optional;
}

.title-wrapper {
  margin: 0;
  background: $title-bg-color;
  color: #ddd;
  border-bottom: 2px solid #111;
  border-top: 1px solid #555;
  box-shadow: 0 2px 3px inset #331e1ed6, 0 -3px 5px inset #33333387;
  text-shadow: -1px -1px 2px black;
  h1 {
    margin-bottom: 0
  }
}

.feature-image-wrap {
  background-color: #FFF;
  padding-bottom: 0px;
  margin-bottom: 0px;
  min-height: 200px;
  border-top: 2px solid #333;
  .feature-image {
    min-height: 400px;
    height: auto;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
  }
}

body { padding: 0 }
nav {
  margin-bottom: 0;
  ul { margin: 0 }
  > div > a {
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
  }
}
