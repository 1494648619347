.bookmark-group {
  @extend section;
  gap: 15px;
}

.bookmark {
  --width-card: 430px;
  @extend aside;
  display: flex;
  flex-direction: column;
  h4 {
    font-size: 1rem;
    font-family: var(--font-family);
    margin: 0;
    line-height: 1.35;
    font-weight: 600;
  }
  img {
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 5px !important;
    padding: 2px !important;
    height: 50px;
    background: white;
  }
  .bookmark__title {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
  }
  .bookmark__right-side {
    flex-grow: 1;
  }
  q {
    margin-left: 10px;
    display: block;
    margin-bottom: 10px;
    opacity: 0.9;
    font-style: italic;
  }
}

.entry-content {
  font-size: 22px;
  @media (prefers-color-scheme: dark) {
    strong {
      color: #f7bfd9;
    }
  }

}
p {
  margin: 1.2em 0;
}
h1, h2, h3, h4, h5 {
  font-weight: 500;
}
h2, h3 {
  margin-top: 50px;
}


.aliexpress {
  @extend aside;
  max-width: 600px;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  display: flex;
  @media (prefers-color-scheme: dark) {
  a:link {
    color: #f7bfd9;
  }
  a:hover {
    color: darken(#f7bfd9, 20%);
  }
  a:visited {
    color: saturate(#f7bfd9, 50%);
  }
  }
  h4 {
    font-size: 1rem;
    font-family: var(--font-family);
    margin: 0;
    line-height: 1.35;
    font-weight: normal;
    a {
      font-weight: normal;
    }
  }
  img {
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 5px !important;
    padding: 2px !important;
    height: 100px;
    background: white;
  }
  .aliexpress__title {
    margin-bottom: 10px;
  }
}
